<template>
  <div>
    <!-- <div class="" vs-lg="12" vs-sm="4" vs-xs="6"  vs-type="flex"> <img src="background-image" alt="">     </div>   -->
    <!-- class="backgroundimage" -->
    <!-- <img src="../assets/images/photo-2.png" > -->

    <!--  -->

    <vs-card style="background-color:#f3f3f3 "><vs-card></vs-card></vs-card>

 
    <!--  -->
     <vs-row vs-lg="12" vs-sm="6" vs-xs="12" vs-justify="center">
       <vs-col vs-lg="6" vs-sm="6" vs-xs="12" vs-justify="center" style="background-color:#f3f3f3 ">
        <vs-row
           vs-w="12"
             vs-justify="center"
        >
        <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="start"
            vs-lg="8"
            vs-sm="4"
            vs-xs="10"
          >
          <p style="color: #00c425; padding-top: 16%;  font-size: 212%; ">
            Connecting Patients to High Quality Medical Care, Anywhere in the
            World
          </p>
        </vs-col>
          <br/>
        </vs-row>

        <vs-row
          vs-w="12"
          vs-justify="center"
          style="padding-top: 1%; margin-right: 0% ;"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="8"
            vs-sm="4"
            vs-xs="10"
          >
          <br/>
            <p style="color: #132953 ; font-size: 212%;">
your medical & wellness journey starts here
            </p>
            <br/>
           
          </vs-col>
        </vs-row>
 <br/>  
        <vs-row
          vs-w="12"
          vs-justify="center"
          style="padding: 0%; margin-right: 32% ;padding-left: 14px;"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <img src="../assets/images/true.png" width="7%" alt="" />
            <p style="color: #132953; font-size: 130%">
              Trust your health to our verified network of doctors
            </p>
            
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="start"
            vs-lg="5"
            vs-sm="4"
            vs-xs="12"
          >
            <img src="../assets/images/true.png" width="7%" alt="" />
            <p style="color: #132953 ;font-size: 125% " >
              Convenient healthcare options with in-person or online visits
            </p>
           
          </vs-col>
        </vs-row>
        <br/>
        
        <vs-row
          vs-w="12"
          vs-justify="center"
          style="margin-right: 32%; padding-bottom: 4% ;padding-left: 14px;"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <img src="../assets/images/true.png" width="7%" alt="" />
            <p style="color: #132953; font-size: 119%">
              Access the best medical professionals around the world
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="start"
            vs-lg="5"
            vs-sm="4"
            vs-xs="12"
          >
            <img src="../assets/images/true.png" width="7%" alt="" />
            <p style="color: #132953 ; font-size: 113%;" >
              Experience world class treatment at top-rated hospitals
            </p>
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col vs-lg="6" vs-sm="6" vs-xs="12" >
          <img src="../assets/images/Clinics-Header-Image.png" alt="" style="width: -webkit-fill-available;">
      </vs-col>
    </vs-row>
    <!--  -->

    <!-- <img src="../assets/images/photo-2.png" style=" background-size: cover;
  background-repeat: round;
  background-attachment: local;" > -->

    <!-- <img src="../assets/images/photo-2.png" > -->

    <!-- <div class="aaa"> <img src="../assets/images/4.png" alt="">
          
        </div> -->

    <div>
      <vs-row vs-justify="center">
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-card>
            <div class="back-grewond2">
              <vs-row vs-justify="flex-center" vs-lg="12" vs-sm="4" vs-xs="12">
                <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                  <vs-col
                    style="padding: 1%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-button
                      color="success"
                      color-text="rgb(50,50,50)"
                      type="border"
                      size="large"
                      href="/SurgeryAndTreatment"
                    >
                      <span style="font-size: 124%">Medical Procedures</span>
                    </vs-button>
                  </vs-col>
                  <vs-col
                    style="padding: 4%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-button
                      color="success"
                      color-text="rgb(50,50,50)"
                      type="border"
                      size="large"
                      href="/AppointmentSearch"
                    >
                      <span style="font-size: 112%"> Online Appointments</span>
                    </vs-button>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-button
                      color="success"
                      color-text="rgb(50,50,50)"
                      type="border"
                      size="large"
                      href="/OurPackages"
                      >WELLNESS PACKAGES</vs-button
                    >
                  </vs-col>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                  style="padding: 2%"
                >
                  <h1 style="color: aliceblue">TOP DESTINATIONS</h1>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                  style="padding: 2%"
                >
                  <vs-row
                    vs-lg="12"
                    vs-sm="4"
                    vs-xs="12"
                    vs-justify="center"
                    style="text-align-last: center"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="https://doclinia.com/wp-content/uploads/2024/04/Flag_of_the_United_States_DoS_ECA_Color_Standard.svg-150x150.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        USA
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/germin-flag.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Germany
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/uea.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        UAE
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Spain.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Spain
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Greece.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Greece
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Egypt.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Egypt
                      </h5>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-lg="12"
                    vs-sm="4"
                    vs-xs="2"
                    vs-justify="center"
                    style="text-align-last: center; padding: 1%"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Czech.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Czech
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/India.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        India
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Thailand.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Thailand
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Brazil.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Brazil
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Poland.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Poland
                      </h5>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="1"
                      vs-sm="4"
                      vs-xs="2"
                      style="display: block"
                    >
                      <img
                        class="all-mig"
                        src="../assets/images/Turkey.png"
                        alt=""
                      />
                      <h5 style="color: aliceblue; align-content: center">
                        Turkey
                      </h5>
                    </vs-col>
                  </vs-row>
                </vs-row>
              </vs-row>
            </div>
          </vs-card>
        </vs-row>
      </vs-row>
    </div>

    <!-- 3line -->
    <div>
      <vs-row vs-justify="center">
        <vs-row
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-card>
            <div class="back-grewond2">
              <vs-row vs-justify="flex-center" vs-lg="12" vs-sm="4" vs-xs="12">
                <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                  </vs-col>
                  <vs-col
                    style="padding: 2%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <h1 style="color: #00c425">WHY</h1>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                  </vs-col>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <h1 style="color: aliceblue">DOCLINIA?</h1>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="3"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="con-vs-slider"
                  ><vs-slider color="success"></vs-slider
                ></vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-row vs-justify="center">
                      <vs-col
                        type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="8"
                      >
                        <vs-card style="border-radius: 10% 9% 10% 9%">
                          <vs-row
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-lg="4"
                            vs-sm="4"
                            vs-xs="12"
                          >
                            <img
                              style="width: 20%; text-align: center"
                              src="../assets/images/icon123456.png"
                              alt=""
                            />

                            <h1
                              style="
                                color: #00c425;
                                text-align: center;
                                padding: 4%;
                              "
                            >
                              Verified, Trusted Global Providers
                            </h1>
                            <h4
                              style="
                                color: #004477;
                                text-align: center;
                                padding: 4%;
                              "
                            >
                              Exceptional Expertise, Distinguish Reputation
                            </h4>
                          </vs-row>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </vs-col>
                  <!--  -->
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-row vs-justify="center">
                      <vs-col
                        type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="8"
                      >
                        <vs-card style="border-radius: 10% 9% 10% 9%">
                          <vs-row
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-lg="4"
                            vs-sm="4"
                            vs-xs="12"
                          >
                            <img
                              style="width: 20%; text-align: center"
                              src="../assets/images/icon12345.png"
                              alt=""
                            />

                            <h1
                              style="
                                color: #00c425;
                                text-align: center;
                                padding: 4%;
                              "
                            >
                              Empowering Personal Care
                            </h1>
                            <h4
                              style="
                                color: #004477;
                                text-align: center;
                                padding: 4%;
                              "
                            >
                              Tailored Solutions, Informed Decisions
                            </h4>
                          </vs-row>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </vs-col>

                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-row vs-justify="center">
                      <vs-col
                        type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="8"
                      >
                        <vs-card style="border-radius: 10% 9% 10% 9%">
                          <vs-row
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            vs-lg="4"
                            vs-sm="4"
                            vs-xs="12"
                          >
                            <img
                              style="width: 20%; text-align: center"
                              src="../assets/images/icon123.png"
                              alt=""
                            />

                            <h1
                              style="
                                color: #00c425;
                                text-align: center;
                                padding: 5%;
                              "
                            >
                              Stress-Free Journey
                            </h1>
                            <h4
                              style="
                                color: #004477;
                                text-align: center;
                                padding: 7%;
                              "
                            >
                              Comprehensive Support, Peace of Mind Guaranteed
                            </h4>
                          </vs-row>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </vs-col>
                </vs-row>
              </vs-row>
            </div>
          </vs-card>
        </vs-row>
      </vs-row>
    </div>
    <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="4"
        vs-xs="12"
      >
      </vs-col>
      <vs-col
        style="padding: 2%"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="4"
        vs-xs="12"
      >
        <h1 style="color: #124179; font-size: -webkit-xxx-large">
          Our Partners
        </h1>
      </vs-col>
    </vs-row>
     <vs-row
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <h3 style="padding-left: 5%">
        Doclinia takes pride in its collaboration with esteemed partners,
        uniting elite healthcare providers to redefine and elevate global
        standards in medical tourism. ?
      </h3>
    </vs-row>
    <vs-row
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
      style="padding: 2%"
    >
      <vs-row
        vs-lg="10"
        vs-sm="4"
        vs-xs="12"
        vs-justify="center"
        style="text-align-last: center; padding: 2%"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Screenshot-2023-12-23-142209-e17.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center">
            Acıbadem 24 Hospitals in Turkey
          </h4>
          <br />
          <br />
          <br />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Sulaiman.png"
            alt=""
          />
          <br />
          <br />
          <h2 style="color: black; align-content: center">
            Dr. Sulaiman AlHabib Dubai
          </h2>
          <br />
          <br />
          <br />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Hayat.png"
            alt=""
          />
          <br />
          <br />
          <h3 style="color: black; align-content: center">
            Hayat Hospital Bursa
          </h3>
          <br />
          <br />
          <br />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/20"
            style="width: 75%"
            src="../assets/images/Kamalaya.png"
            alt=""
          />
          <br />
          <br />
          <h3 style="color: black; align-content: center">Kamalaya Thailand</h3>
          <br />
          <br />
          <br />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Quironsalud.png"
            alt=""
          />
          <br />
          <br />
          <h3 style="color: black; align-content: center">
            Quironsalud Hospitals Spain
          </h3>
          <br />
          <br />
          <br />
        </vs-col>
      </vs-row>
      <!-- 2line img -->
      <vs-row
        vs-lg="10"
        vs-sm="4"
        vs-xs="12"
        vs-justify="center"
        style="text-align-last: center; padding: 2%"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Medical.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center">
            Dr. Faris Medical Centre Cairo
          </h4>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Nicolas.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center">
            Drs. Nicolas & Asp Centers Dubai
          </h4>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Katameya.png"
            alt=""
          />
          <br />
          <br />
          <h3 style="color: black; align-content: center">
            Katameya Clinic Cairo
          </h3>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/20"
            style="width: 75%"
            src="../assets/images/Palasiet.png"
            alt=""
          />
          <br />
          <br />
          <h3 style="color: black; align-content: center">
            Palasiet Spa Spain
          </h3>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/AbuDhabi.png"
            alt=""
          />
          <br />
          <br />
          <h5 style="color: black; align-content: center">
            Abu Dhabi Stem Cells Centre Abu Dhabi
          </h5>
          <br />
          <br />
          <br />
        </vs-col>
      </vs-row>
      <!-- 3line img -->
      <vs-row
        vs-lg="10"
        vs-sm="4"
        vs-xs="12"
        vs-justify="center"
        style="text-align-last: center"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Teknon.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center">
            Centro Medico Teknon Spain
          </h4>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Horovice.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center; padding-left: 4%">
            Horovice Hospital Czech Republic
          </h4>
          <br />
          <br />
          <br />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="12"
          style="display: block"
        >
          <img
            class="shadow-lg shadow-indigo-500/40"
            style="width: 75%"
            src="../assets/images/Fakeeh.png"
            alt=""
          />
          <br />
          <br />
          <h4 style="color: black; align-content: center">
            Fakeeh University Hospital Dubai
          </h4>
          <br />
          <br />
          <br />
        </vs-col>
      </vs-row>
    </vs-row>
    <!-- foter -->
    <div>
      <vs-row vs-justify="center">
        <vs-row
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="4"
          vs-xs="12"
        >
          <vs-card>
            <div class="back-grewond2">
              <vs-row vs-justify="flex-center" vs-lg="12" vs-sm="4" vs-xs="12">
                <vs-col vs-lg="4" vs-sm="4" vs-xs="12" style="padding: 4%">
                  <vs-row
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <img src="../assets/images/doclnia.png" />

                    <h2 style="color: aliceblue; text-align: left">
                      Brand of Cure Journey Portalwww.cure-journey.com
                    </h2>
                    <h3 style="padding: 2%; text-align: left">
                      <a style="color: aliceblue" href="tel:+971 4 517 7676">
                        <img
                          src="../assets/images/call.png"
                          width="7%"
                          alt=""
                        />+971 4 517 7676</a
                      >
                    </h3>
                    <h3 style="padding: 2%; text-align: left">
                      <a
                        style="color: aliceblue"
                        href="https://api.whatsapp.com/send/?phone=%2B201222246224&text&type=phone_number&app_absent=0"
                      >
                        <img
                          src="../assets/images/wahts.png"
                          width="7%"
                          alt=""
                        />
                        +201222246224</a
                      >
                    </h3>
                    <h3 style="padding: 2%; text-align: left; color: azure">
                      PRIVACY POLICY
                    </h3>
                    <vs-row class="con-slider"
                      ><vs-slider color="success"> </vs-slider>
                    </vs-row>
                    <h4 style="padding: 2%; text-align: left">
                      <a style="color: aliceblue" href="/pages/termsconditions">
                        >> Terms & Conditions</a
                      >
                    </h4>
                  </vs-row>
                </vs-col>
                <!--  -->
                <vs-col vs-lg="4" vs-sm="4" vs-xs="12" style="padding: 4%">
                  <h2 style="color: aliceblue">COLLABORATE</h2>
                  <vs-row class="con-sliderr"
                    ><vs-slider color="success"> </vs-slider>
                  </vs-row>
                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/pages/Doctors/"> > Doctors</a>
                  </h3>

                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/pages/Doctors/"> > Hospitals</a>
                  </h3>
                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/pages/Doctors/">
                      > Strategic Alliances
                    </a>
                  </h3>

                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/pages/JoinOurTeam/">
                      > Join Our Team</a
                    >
                  </h3>
                  <!-- style="width: 75%" -->
                  <!--  -->
                  <!-- <img
                    class="shadow-lg shadow-indigo-500/40"
                    style="width: 20%; padding: 1%"
                    src="../assets/images/Visa.png"
                    alt=""
                  /> -->
                  <!--  -->
                  <img
                    class="shadow-lg shadow-indigo-500/40"
                    style="width: 20%; padding: 1%"
                    src="../assets/images/visa2.png"
                    alt=""
                  />

                  <img
                    class="shadow-lg shadow-indigo-500/40"
                    style="width: 20%; padding: 1%"
                    src="../assets/images/visa3.png"
                    alt=""
                  />
                  <img
                    class="shadow-lg shadow-indigo-500/40"
                    style="width: 20%; padding: 1%"
                    src="../assets/images/visa4.png"
                    alt=""
                  />
                  <!-- </vs-row> -->
                </vs-col>
                <!--  -->
                <!--  -->
                <vs-col vs-lg="4" vs-sm="4" vs-xs="12" style="padding: 4%">
                  <h2 style="color: aliceblue">PATIENT CARE</h2>
                  <vs-row class="con-sliderr"
                    ><vs-slider color="success"> </vs-slider>
                  </vs-row>
                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/pages/Contact-us">
                      > Contact Us
                    </a>
                  </h3>

                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/FAQQQ"> >FAQ </a>
                  </h3>
                  <h3 style="padding: 2%">
                    <a style="color: aliceblue" href="/patientwaiverr">
                      >Patient Waiver
                    </a>
                  </h3>

                  <h6>
                    <a style="color: aliceblue" href="Doctors">
                      We accept online payments using VISA and MasterCard
                      credit/debit card in USD
                    </a>
                  </h6>
                  <!-- style="width: 75%" -->
                  <h1 style="color: aliceblue; padding: 1%">FOLLOW US</h1>

                  <a
                    style="color: aliceblue"
                    href="https://www.facebook.com/Doclinia"
                  >
                    <img
                      class="shadow-lg shadow-indigo-500/40"
                      style="width: 10%; padding: 1%"
                      src="../assets/images/face1.png"
                      alt=""
                    />
                  </a>

                  <a
                    style="color: aliceblue"
                    href="https://www.instagram.com/doclinia.uae/"
                  >
                    <img
                      class="shadow-lg shadow-indigo-500/40"
                      style="width: 10%; padding: 1%"
                      src="../assets/images/instgram.png"
                      alt=""
                    />
                  </a>

                  <a
                    style="color: aliceblue"
                    href="https://www.linkedin.com/company/doclinia/"
                  >
                    <img
                      class="shadow-lg shadow-indigo-500/40"
                      style="width: 10%; padding: 1%"
                      src="../assets/images/linked.png"
                      alt=""
                    />
                  </a>

                  <a
                    style="color: aliceblue"
                    href="https://www.tiktok.com/@doclinia"
                  >
                    <img
                      class="shadow-lg shadow-indigo-500/40"
                      style="width: 10%; padding: 1%"
                      src="../assets/images/tiktok.png"
                      alt=""
                    />
                  </a>
                </vs-col>
                <!--  -->
              </vs-row>
            </div>
          </vs-card>
        </vs-row>
      </vs-row>
    </div>
    <!-- foter -->
  </div>

  <!-- </div> -->
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      rating: 0,
      PhotoPath: "",
      baseURL: domain,
      model:{
         DoctorID:0,
        PatientReserviedSessionID:0,
        Comment:""
      },
      doctor: {
        
      }
    };
  },
  components: {
    StarRating,
    imageLazy
  },
  props: {
    DoctorRate: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
   
  },
  mounted() {
    console.log(this.data);
  },
  created() {
    debugger
    this.model.Comment = "";
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

      if(this.$route.params.DoctorID)
      {
        this.model.DoctorID = this.$route.params.DoctorID;
        this.model.PatientReserviedSessionID = this.$route.params.PatientReserviedSessionID;
      }

      else if(this.DoctorRate.DoctorID)
      {
          this.model.DoctorID = this.DoctorRate.DoctorID
          this.model.PatientReserviedSessionID = this.DoctorRate.PatientReserviedSessionID
      }

        this.$store.dispatch("DoctorList/GetDoctor",  this.model.DoctorID).then(res => {
        this.doctor = res.data.Data;
        this.$vs.loading.close();
      });
  }
};
</script>
<style>
/* .body{
   position: absolute;
      background-image: url(http://doclinia.com/wp-content/uploads/2022/08/4.jpeg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
  }
  } */

.back-grewond2 {
  background-image: url("../assets/images/4.png");
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
.backgroundimage {
  background-image: url("../assets/images/photo-2.png");
  background-size: cover;
  background-repeat: round;
  background-attachment: local;

  /* background-size: auto;
    background-repeat: no-repeat;
    background-attachment: unset; */
  /* height: 100vh;
  position: relative; */
  /* background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; */

  /* height: auto;
    max-width: 100%; */
}
/* .vs-card--content {
    padding: 0px;
    font-size: .8rem;
    margin-bottom: 15px;
} */
.all-mig {
  width: 50%;
  /* padding: 1%; */
  border-radius: 50% 50% 50% 50%;
}
.con-vs-slider {
  width: 8%;
  /* position: relative; */
  left: 0;
  margin: 16px 0;
  display: block;
  min-width: 7%;
  z-index: 100;
}
.vs-slider--circle {
  width: 0px;
  height: 0px;
  position: fixed;
  top: -6px;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin: 0;
  z-index: 200;
}
.con-slider {
  justify-content: left;
  /* width: 8%; */
  /* position: relative; */
  /* left: 0; */
  margin: 16px 0;
  display: block;
  min-width: 7%;
  z-index: 100;
  /* right: 50px */
}
.con-sliderr {
  /* justify-content: center; */
  /* width: 8%; */
  /* position: relative; */
  /* left: 0; */
  margin: 16px 0;
  display: block;
  min-width: 7%;
  z-index: 100;
  /* right: 50px */
}
.shadow-lg {
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 62%), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
}
/* .con-vs-slider {
    width: 100%; 
     position: relative; 
    left: 0;
    margin: 16px 0;
    display: block;
    min-width: 5%;
    z-index: 100;
} */

/*  */
</style>
